<template>
  <b-overlay
    :show="$apollo.loading || mutationLoading"
    rounded="sm"
  >
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        class="form-inline"
        label="Search"
        label-size="sm"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search Masterclass"
          type="text"
        />
        <b-button
          v-if="$route.params.oid"
          variant="outline-primary mr-1"
          @click="showAll = !showAll"
        >
          {{ showAll ? 'Show Current' : 'Show All' }}
        </b-button>
      </b-form-group>
    </div>

    <meetings-table
      :columns="columns"
      :get-exercise-link="getExerciseLink"
      :on-review-click="openRatingModal"
      :rows="rows"
      :search-term="searchTerm"
    />

    <!-- Rating Modal -->
    <b-modal
      id="ratingstable"
      size="sm"
      ok-title="Submit"
      :title="ratingModalTitle"
      @ok="submitRating"
    >
      <b-row>
        <b-col cols="12">
          <h6>Rate on a scale of 1-10 (10 being the highest)</h6>
          <div class="d-flex pb-1 justify-content-between">
            <feather-icon
              v-for="n in 10"
              :key="n"
              icon="StarIcon"
              size="28"
              :fill="rating >= n ? '#7367F0' : '#fff'"
              stroke="#7367F0"
              style="cursor: pointer;"
              @click="setRating(n)"
            />
          </div>
          <b-form-group
            label="Comment For Rating"
            label-for="ratingcomment"
          >
            <b-form-textarea
              id="ratingcomment"
              v-model="ratingComment"
              placeholder="Comment about your rating"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCol, BFormGroup, BFormInput, BFormTextarea, BModal, BRow,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import MeetingsTable from '@/components/meetings/MeetingsTable.vue'

export default {
  components: {
    MeetingsTable,
    BFormTextarea,
    BRow,
    BCol,
    BModal,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      rows: [],
      showAll: false,
      selectedMeeting: 0,
      mutationLoading: false,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Title',
          field(row) {
            return `${row?.title}`
          },
          tdClass: 'text-capitalize',
        },
        {
          label: 'Module',
          field(row) {
            return row.programs_operationstable?.title
          },
          filterOptions: {
            enabled: true,
            customFilter: true,
            placeholder: 'Search Module Description',
          },
        },
        {
          label: 'Mentors',
          field(row) {
            return row.mentor_assignments.map(dta => dta.programs_partnerstable.users_customuser?.full_name)
          },
        },
        {
          label: 'Date',
          field: 'date',
        },
        {
          label: 'Ratings',
          field(row) {
            if (!row.ratings_tables_aggregate.aggregate.avg.rating) return 'Write Review'
            return `${row.ratings_tables_aggregate.aggregate.avg.rating} (${row.ratings_tables_aggregate.aggregate.count})`
          },
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rating: 0,
      ratingComment: '',
      searchTerm: '',
    }
  },
  computed: {
    ratingModalTitle() {
      return this.rows[this.selectedMeeting] ? `Ratings for ${this.rows[this.selectedMeeting].title}` : 'Ratings'
    },
    useOperationId() {
      if (this.$route.params.oid) {
        if (!this.showAll) {
          return `operations_id: {_eq: ${this.$route.params.oid}}`
        }
      }
      return ''
    },
  },
  methods: {
    getExerciseLink(rowId) {
      return {
        name: 'mentor-program-select',
        params: { id: this.$route.params.id, pid: this.$route.params.pid, oid: rowId },
        hash: '#Exercises',
      }
    },
    openRatingModal(meeting) {
      this.selectedMeeting = this.rows.findIndex(r => r.id === meeting.id)
      this.$bvModal.show('ratingstable')
    },
    setRating(value) {
      this.rating = value
    },
    submitRating() {
      this.mutationLoading = true
      this.$apollo
        .mutate({
          mutation: gql`
        mutation InsertRating($operationsId: Int!, $rating: Int!, $remarks: String!) {
          insert_ratings_table_one(
            object: {
              operations_id: $operationsId,
              rating: $rating,
              remarks: $remarks
            },
            on_conflict: { constraint: ratings_table_pkey, update_columns: [rating, remarks] }
          ) {
            id
          }
        }
      `,
          variables: {
            operationsId: this.rows[this.selectedMeeting].id,
            rating: this.rating,
            remarks: this.ratingComment,
          },
        })
        .then(({ data }) => {
          this.$toast({
            title: data.insert_ratings_table_one.id
              ? 'Rating added successfully'
              : 'Failed to add rating',
            icon: data.insert_ratings_table_one.id ? 'CheckIcon' : 'XIcon',
            variant: data.insert_ratings_table_one.id ? 'success' : 'warning',
          })
          this.$apollo.queries.rows.refetch()
          this.rating = 0
          this.ratingComment = ''
        })
        .finally(() => {
          this.mutationLoading = false
        })
    },
  },

  apollo: {
    rows: {
      query() {
        return gql`
        query GetMasterclass($programId: Int!) {
          programs_operationstable(
            order_by: { id: desc }
            where: {
              program_id: { _eq: $programId }
              operations_type: { _eq: "masterclass" }
              ${this.useOperationId}
            }
          ) {
            id
            operations_id
            operations_type
            title
            meeting
            recording
            description
            begindate_timestamp
            enddate_timestamp
            programs_operationstable {
              id
              title
            }
            status
            programs_scheduletables {
              meeting_link
              recording_link
            }
            programs_assignmenttables {
              id
            }
            mentor_assignments {
              id
              description
              programs_partnerstable {
                id
                users_customuser {
                  full_name
                }
              }
            }
            ratings_tables_aggregate {
              aggregate {
                avg {
                  rating
                }
                count(distinct: false)
              }
            }
          }
        }
      `
      },
      variables() {
        return {
          programId: this.$route.params.id,
        }
      },
      update: data => data.programs_operationstable,
    },
  },
}
</script>
